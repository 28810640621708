.numbers-wrapper {
    position: relative;

    @media (max-width: 500px) {
        padding-bottom: 0;
    }

    .section-head {
        .heading-bg {
            bottom: 25px;
        }

        .section-title {
            color: $color-secondary;
        }
    }

    .body-text {
        color: $color-gray;
    }

    .small-heading {
        font-size: 20px;
        line-height: 30px;
        color: $color-secondary;
        font-weight: 700;
        font-family: $oxygen;
        margin-bottom: 15px;
    }

    .numbers-profile {
        margin-top: 40px;

        .profile-img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            margin-right: 30px;
        }

        .profile-info {
            margin-right: 50px;
            .name {
                font-size: 20px;
                color: $color-secondary;
                font-weight: 700;
            }

            .role {
                font-size: 16px;
                line-height: 27px;
                color: $color-gray;
                font-weight: 400;
                font-family: $oxygen;
            }
        }
    }

    .right-content {
        min-width: 100%;
        position: relative;

        @media (max-width: 500px) {
            display: none;
        }

        &::after {
            content: "";
            width: 100%;
            height: 800px;
            background-image: url("../../images/illustration/map.png");
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            right: 0;
            top: -220px;
            z-index: -1;
            overflow-x: hidden;
        }

        @media (max-width: 991px) {
            padding-bottom: 60px;
        }
    }

    .progressbar-wrapper {
        position: relative;

        @media (max-width: 991px) {
            margin-top: 300px;
        }

        @media (max-width: 500px) {
            margin-top: 250px;
        }

        .circular-progress {
            position: relative;
            margin-top: 0% !important;
        }

        .progress-text {
            position: absolute;
            margin-top: 90px;
            color: $color-secondary;
            font-weight: 500;

            &.small {
                margin-top: 70px;
                font-size: 18px;
            }
        }

        .circlechart {
            .circle-chart__background {
                stroke: #efefef;
                stroke-width: 0.5;
                stroke-dasharray: 2;
                fill: none;
            }

            .circle-chart__circle {
                stroke: $color-primary;
                stroke-width: 1.5;

                stroke-linecap: round;
            }

            .circle-chart__percent {
                fill: $color-primary;
                font-family: $roboto;
                font-weight: 400;
            }

            .circle-chart__subline {
                fill: $color-secondary;
                font-family: $roboto;
            }
        }

        .circle-1 {
            position: absolute;
            left: 100px;
            top: -120px;

            @media (max-width: 1199px) {
                right: 0px;
            }

            svg {
                width: 280px;
                height: 280px;

                @media (max-width: 500px) {
                    width: 180px;
                    height: 200px;
                }
            }
        }

        .circle-2 {
            position: absolute;
            right: 80px;
            bottom: 50px;

            @media (max-width: 500px) {
                left: 0;
            }

            svg {
                width: 190px;
                height: 190px;

                @media (max-width: 500px) {
                    width: 150px;
                    height: 150px;
                }
            }
        }

        .circle-3 {
            position: absolute;
            right: 0;
            top: 0;

            @media (max-width: 500px) {
                left: 0;
            }

            svg {
                width: 230px;
                height: 230px;

                @media (max-width: 500px) {
                    width: 160px;
                    height: 160px;
                }
            }
        }
    }

    .cta-wrapper {
        width: 100%;
        min-height: 250px;
        background-color: $color-white;
        box-shadow: $box-shadow-1;
        margin-top: -300px;
        margin-bottom: 200px;

        @media (max-width: 991px) {
            margin-top: -225px;
        }

        @media (max-width: 767px) {
            padding-bottom: 20px;
        }

        @media (max-width: 575px) {
            margin-bottom: 100px;
        }

        .cta-img {
            width: 350px;
            height: 250px;
            background-position: bottom;

            @media (max-width: 767px) {
                width: 100%;
            }
        }

        .cta-icon {
            min-width: 90px;
            height: 90px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $color-primary;
            border: 10px solid $color-white;
            font-size: 30px;
            color: $color-white;
            margin-left: -70px;
            margin-right: 30px;
            position: relative;
            z-index: 10;

            @media (max-width: 767px) {
                margin-left: 0;
                margin-right: 0;
                text-align: center;
                margin-top: -50px;
            }
        }

        .cta-details {
            margin-left: 40px;

            @media (max-width: 767px) {
                margin-top: 20px;
            }

            @media (max-width: 575px) {
                margin-left: 20px;
            }
            .cta-heading {
                font-size: 30px;
                line-height: 39px;
                color: $color-secondary;
                font-weight: 500;

                @media (max-width: 575px) {
                    font-size: 25px;
                }
            }

            .cta-num {
                color: $color-primary;
            }
        }
    }
}
