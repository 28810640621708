.blog-card {
    border: 1px solid #ebebeb;
    position: relative;

    .card-thumb {
        height: 260px;
        width: 100%;
    }

    .category {
        display: inline-block;
        padding: 10px 20px;
        background-color: $color-primary;
        color: $color-white;
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
        font-family: $oxygen;
        position: absolute;
        top: 20px;
        left: 20px;
    }

    .blog-meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .meta-item {
            display: flex;
            margin-right: 20px;
            &.comment {
                position: relative;
                &::before,
                &::after {
                    content: "";
                    width: 4px;
                    height: 4px;
                    background-color: $color-primary;
                    position: absolute;
                }

                &::after {
                    left: -15px;
                    top: 7px;
                }

                &::before {
                    right: -15px;
                    top: 7px;
                }
            }

            @media (max-width: 380px) {
                margin-bottom: 10px;
            }

            @media (max-width: 400px) {
                margin-right: 20px;
            }
        }

        .meta-item,
        .meta-item-link {
            font-size: 14px;
            line-height: 18px;
            color: $color-gray;
            font-weight: 400;
            font-family: $oxygen;
        }

        .meta-icon {
            margin-right: 10px;
        }

        .meta-item-link {
            font-size: 20px;
        }
    }

    .blog-title {
        font-size: 20px;
        line-height: 26px;
        font-weight: 500;
        color: $color-secondary;
        font-family: $roboto;
        display: inline-block;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .blog-card-content {
        padding: 30px;
    }

    .button {
        margin-top: 30px;
    }
}

.blog-card-wrapper {
    margin-bottom: 100px;
    overflow-x: hidden;

    .blog-quote {
        min-height: 100%;
        padding: 40px;
        background-color: $color-secondary;
        position: relative;

        &::before {
            content: "";
            height: 100%;
            width: 100%;
            background-image: url("../../images/icons/quote-icon.png");
            background-repeat: no-repeat;
            position: absolute;
            top: 40px;
            left: 20px;
        }

        .quote-heading {
            color: $color-white;
            margin-bottom: 40px;
        }

        .quote-author {
            color: $color-gray-2;
        }
    }

    .blog-pagination-wrapper {
        margin-top: 55px;
        .blog-pagination {
            display: flex;
            justify-content: center;

            .pagination-item {
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }

            .pagination-link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                border: 1px solid #ebebeb;
                font-size: 20px;
                color: $color-gray;
                font-weight: 400;
                font-family: $roboto;

                &.active,
                &:hover {
                    background-color: $color-primary;
                    color: $color-white;
                }
            }
        }
    }
}

// SINGLE POST
.single-post-wrapper {
    margin-bottom: 100px;
    overflow-x: hidden;

    .post-details {
        .blog-card {
            position: relative;
            border: none;
        }

        .card-thumb {
            height: 350px;
            width: 100%;

            @media (max-width: 575px) {
                height: 250px;
            }
        }

        .category {
            display: inline-block;
            padding: 10px 20px;
            background-color: $color-primary;
            color: $color-white;
            font-size: 14px;
            line-height: 18px;
            font-weight: 700;
            font-family: $oxygen;
            position: absolute;
            top: 20px;
            left: 20px;
        }

        .blog-meta {
            display: flex;
            justify-content: stretch;

            .meta-item {
                &:not(:last-child) {
                    margin-right: 30px;
                }
                &.comment {
                    position: relative;
                    &::before,
                    &::after {
                        content: "";
                        width: 4px;
                        height: 4px;
                        background-color: $color-primary;
                        position: absolute;
                    }

                    &::after {
                        left: -15px;
                        top: 7px;
                    }

                    &::before {
                        right: -15px;
                        top: 7px;
                    }
                }
            }

            .meta-item,
            .meta-item-link {
                font-size: 14px;
                line-height: 18px;
                color: $color-gray;
                font-weight: 400;
                font-family: $oxygen;
            }

            .meta-icon {
                margin-right: 10px;
            }

            .meta-item-link {
                font-size: 20px;
            }
        }

        .post-heading {
            font-size: 34px;
            line-height: 41px;
            color: $color-secondary;
            font-weight: 700;
            margin-bottom: 20px;

            @media (max-width: 767px) {
                font-size: 28px;
            }
        }

        .first-letter {
            min-width: 60px;
            height: 60px;
            background-color: $color-primary;
            color: $color-white;
            font-size: 34px;
            color: $color-white;
            font-weight: 700;
            font-family: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .post-quote {
            width: 100%;
            margin: 60px 0px;
            padding: 40px;
            padding-right: 100px;
            border-left: 1px solid $color-primary;
            position: relative;

            @media (max-width: 767px) {
                padding: 30px;
            }

            &::before {
                content: "";
                width: 100%;
                height: 100%;
                background-image: url("../../images/icons/quote-orange.png");
                background-repeat: no-repeat;
                position: absolute;
                left: 90%;
                top: 50px;
                z-index: -1;

                @media (max-width: 767px) {
                    display: none;
                }
            }

            .quote-title {
                font-size: 20px;
                line-height: 30px;
                color: $color-secondary;
                font-weight: 700;
                font-family: $oxygen;
            }
        }

        .post-thumb-2 {
            width: 100%;
            height: 360px;
            margin: 50px 0px;

            @media (max-width: 767px) {
                height: 250px;
            }
        }

        .post-heading-2 {
            font-size: 30px;
            line-height: 39px;
            color: $color-secondary;
            font-weight: 500;
            margin-bottom: 20px;

            @media (max-width: 767px) {
                font-size: 28px;
            }
        }
    }

    .related-posts {
        margin: 110px 0px;

        @media (max-width: 991px) {
            margin: 90px 0px;
        }
    }

    .related-post-heading {
        font-size: 34px;
        line-height: 41px;
        color: $color-secondary;
        font-weight: 700;
        font-family: $roboto;
        margin-bottom: 30px;
    }

    .blog-post-nav {
        margin-top: 20px;
    }

    .comments-wrapper {
        .comment-box {
            &:not(:last-child) {
                border-bottom: 1px solid #ebebeb;
                margin-bottom: 20px;
            }
        }

        .comment-reply {
            margin-left: 70px;

            @media (max-width: 450px) {
                margin-left: 0;
            }
        }

        .box-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .comment-profile {
            display: flex;
            align-items: center;
            .avatar {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: $color-primary;
                margin-right: 15px;
            }

            .name {
                font-size: 20px;
                line-height: 26px;
                color: $color-secondary;
                font-weight: 500;
                font-family: $roboto;
            }

            .comment-meta {
                font-size: 14px;
            }
        }

        .comment-reply-btn {
            display: flex;
            align-items: center;

            .reply-text,
            .reply-icon {
                color: $color-primary;
                font-size: 16px;
                line-height: 27px;
                font-weight: 400;
                font-family: $oxygen;
            }

            .reply-icon {
                margin-right: 10px;
            }
        }

        .comment-text {
            padding: 20px 75px;

            @media (max-width: 450px) {
                padding: 20px;
            }
        }
    }

    .post-comment {
        margin-top: 100px;

        @media (max-width: 991px) {
            margin-top: 90px;
        }

        input,
        textarea {
            padding: 15px 20px;
            border: 1px solid #ebebeb;
            margin-bottom: 20px;
        }

        input {
            min-width: 235px;
            &:not(:last-child) {
                margin-right: 15px;
            }

            @media (max-width: 991px) {
                width: 100%;
            }
        }

        ::placeholder {
            font-size: 14px;
            line-height: 18px;
            color: #5f6168;
            font-weight: 400;
            font-family: $oxygen;
        }

        textarea {
            min-height: 180px;
            width: 100%;
        }

        .button {
            margin-top: -5px;
        }
    }
}

.post-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 55px;

    .tags-item {
        &:first-child {
            font-size: 18px;
            line-height: 23px;
            color: $color-secondary;
            font-weight: 400;
            font-family: $roboto;
        }

        &:not(:last-child) {
            margin-right: 15px;
        }
    }

    .tags-link {
        font-size: 16px;
        line-height: 27px;
        color: $color-gray;
        font-weight: 400;
        font-family: $oxygen;
    }
}

.single-post-sidebar {
    padding-left: 35px;
    @media (max-width: 991px) {
        margin-top: 90px;
        padding-left: 0;
    }
    .section-head {
        .section-title {
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 17px;
        }
    }

    .widget-search {
        width: 100%;
        position: relative;

        .search-field {
            width: 100%;
            border: 1px solid #ebebeb;
            padding: 10px 30px;
        }

        .search-button {
            width: 42px;
            height: 42px;
            background-color: $color-primary;
            color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            right: 4px;
            top: 4px;
        }
    }

    .widget-author {
        padding: 60px 10px;
        background-color: $color-secondary;
        text-align: center;
        margin-top: 30px;
        position: relative;
        z-index: 1;

        &::after {
            content: "";
            width: 100%;
            height: 100%;
            background-image: url("../../images/blog/author-illustration.png");
            background-repeat: no-repeat;
            position: absolute;
            top: 0;
            right: -30px;
            z-index: -1;
        }

        .author-avatar {
            width: 170px;
            height: 170px;
            border-radius: 50%;
            margin: 0 auto;
            margin-bottom: 20px;
        }

        .author-name {
            font-size: 20px;
            line-height: 26px;
            color: $color-white;
            font-weight: 500;
            font-family: $roboto;
            display: block;
        }

        .author-role {
            color: $color-gray-2;
        }

        .author-socials {
            display: flex;
            justify-content: center;
            margin-top: 30px;

            .social-item {
                font-size: 20px;
                &:not(:last-child) {
                    margin-right: 20px;
                }

                &:hover {
                    color: $color-primary;
                }
            }
        }
    }

    .widget-recent-post {
        border: 1px solid #ebebeb;
        padding: 30px;
        margin-top: 30px;

        .post-item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        .post-info {
            .post-title {
                display: inline-block;
                font-size: 18px;
                line-height: 23px;
                color: $color-secondary;
                font-weight: 400;
                font-family: $roboto;
                margin-bottom: 10px;

                &:hover {
                    color: $color-primary;
                }
            }

            .post-meta {
                font-size: 14px;
                line-height: 18px;
                color: $color-gray;
                font-weight: 400;
                font-family: $oxygen;
                display: block;
            }
        }

        .post-thumb {
            min-width: 70px;
            height: 70px;
            margin-right: 20px;
        }
    }

    .widget-tags {
        margin-top: 30px;
        border: 1px solid #ebebeb;
        padding: 30px;
        .post-tags {
            margin-top: 0;
        }

        .tags-item {
            margin-bottom: 5px;
        }
    }

    .widget-gallery {
        margin-top: 30px;
        border: 1px solid #ebebeb;
        padding: 30px;

        .row > * {
            padding-right: 4px;
            padding-left: 4px;
        }
    }
}
