.about-wrapper {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: -50px;

    .left-content {
        width: 100%;
        height: 100%;
    }

    .right-content {
        @media (max-width: 991px) {
            padding-top: 100px;
        }

        @media (max-width: 575px) {
            padding-top: 60px;
        }

        .section-head {
            margin-bottom: 20px;

            .heading-bg {
                bottom: 25px;

                @media (max-width: 1199px) {
                    left: 10px;
                }
            }
        }

        .body-text {
            padding-right: 80px;

            @media (max-width: 400px) {
                padding-right: 30px;
            }
        }

        .right-content-items {
            margin-top: 20px;
            margin-bottom: 40px;

            .single-item {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }

            .single-item-icon {
                color: $color-primary;
                margin-right: 10px;
            }
        }
    }
}

.about-two-wrapper {
    &.numbers-wrapper {
        .right-content {
            &::after {
                background-image: none;
            }
        }
    }

    .left-content {

        .small-heading,
        .body-text {
            padding-right: 80px;

            @media (max-width: 767px) {
                padding-right: 0;
            }
        }
    }

    .right-content {
        position: relative;

        margin-bottom: 50px;

        .about-thumb {
            max-width: 460px;
            height: 590px;

            @media (max-width: 991px) {
                height: 400px;
            }
        }

        .right-card {
            background-color: $color-primary;
            padding: 30px;
            max-width: 500px;
            position: absolute;
            bottom: -50px;
            right: 0;
        }

        .body-text {
            font-size: 20px;
            line-height: 26px;
            color: $color-white;
            font-weight: 500;
            font-family: $roboto;
        }

        .card-icon {
            margin-right: 40px;
        }
    }
}

.why-us-wrapper {
    background-image: url("../../images/illustration/why-us-bg.svg");
    background-repeat: no-repeat;
    background-position: left 500px;

    .left-content {

        .body-text,
        .section-title {
            padding-right: 150px;

            @media (max-width: 1199px) {
                padding-right: 0;
            }
        }
    }

    .right-card {
        min-width: 270px;
        padding: 30px;
        margin-bottom: 20px;
        border: 1px solid #ebebeb;
        background-image: url("../../images/illustration/services-card-bg.svg");
        background-repeat: no-repeat;
        background-size: cover;

        &.go-top {
            margin-top: -50px;
        }

        .card-heading {
            font-size: 20px;
            line-height: 26px;
            color: $color-secondary;
            font-weight: 500;
            margin-top: 20px;
            margin-bottom: 15px;
        }
    }
}

.vision-wrapper {
    overflow-x: hidden;

    .experience-card {
        background-color: $color-secondary;
        display: flex;
        align-items: center;
        margin-left: 20px;
        overflow-x: hidden;
        position: relative;
        background-image: url("../../images/spider-net-illustration.png");
        background-size: cover;

        .card-heading {
            padding: 0px 70px;

            @media (max-width: 1400px) {
                padding: 0px 30px;
            }
        }

        .card-num {
            font-size: 100px;
            line-height: 70px;
            color: $color-primary;
            font-weight: 700;
            padding: 0px 40px;

            @media (max-width: 767px) {
                font-size: 45px;
                line-height: 50px;
            }
        }

        .card-text {
            font-size: 60px;
            color: #94a6c41f;
            font-weight: 700;
            line-height: 50px;
            font-family: $oxygen;
            text-transform: uppercase;

            @media (max-width: 767px) {
                font-size: 45px;
                line-height: 50px;
            }
        }
    }

    .left-card {
        max-width: 100%;
        height: 380px;

        @media (max-width: 767px) {
            height: 200px;
        }
    }

    .right-content {
        padding: 100px;
        padding-top: 160px;
        min-height: 100%;
        background-color: $color-secondary;
        // max-width: 490px;

        @media (max-width: 991px) {
            padding: 50px;
            padding-top: 80px;
            padding-bottom: 80px;
        }

        @media (max-width: 575px) {
            padding: 20px;
            padding-top: 80px;
            padding-bottom: 80px;
        }

        .section-head {
            max-width: 490px;

            .section-title,
            .body-text {
                color: $color-white;
            }

            .heading-bg {
                z-index: 1;
                bottom: 100px;

                @media (max-width: 1342px) {
                    bottom: 160px;
                }

                @media (max-width: 991px) {
                    bottom: 100px;
                }
            }
        }
    }

    .vision-progress {
        max-width: 490px;

        @media (max-width: 991px) {
            margin-top: 50px;
        }

        .single-progress {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        .progress-heading,
        .skill-bar-percent {
            font-size: 18px;
            line-height: 30px;
            color: $color-white;
            font-weight: bold;
            font-family: $oxygen;
            margin-bottom: 10px;
        }

        .progress-bar-wrapper {
            width: 100%;
        }

        .skillbar {
            position: relative;
            display: block;
            margin-bottom: 15px;
            width: 100%;
            height: 11px;
            border: 1px solid #94a6c41f;
        }

        .skillbar-bar {
            height: 3px;
            width: 0px;
            background: $color-primary;
            margin-top: 3px;
            margin-left: 3px;
        }

        .skill-bar-percent {
            position: absolute;
            right: 10px;
            top: -40px;
            height: 15px;
        }

        .progress {
            height: 4px;
            margin: 3px;

            .progress-bar {
                background-color: $color-primary;
            }
        }
    }

    .row>* {
        padding-right: 0;
        padding-left: 0;
    }
}

.work-process-wrapper {
    margin-bottom: 60px;
    background-image: url("../../images/illustration/work-process.svg");
    background-repeat: no-repeat;
    background-position: bottom left;

    .section-head {
        .heading-bg {
            left: 32%;
            bottom: -30px;

            @media (max-width: 1199px) {
                left: 28%;
            }

            @media (max-width: 767px) {
                left: 25%;
            }
        }
    }

    .process-card {
        padding: 40px;

        @media (max-width: 1199px) {
            padding: 20px;
        }

        .card-divider {
            position: relative;
            right: -214px;
            bottom: -70px;

            @media (max-width: 1199px) {
                right: -185px;
            }

            @media (max-width: 991px) {
                right: -122px;
                bottom: -43px;

            }

            @media (max-width: 767px) {
                display: none;
            }

            &::before {
                content: "";
                height: 1px;
                width: 60px;
                background-color: #ebebeb;
                position: absolute;
                top: 0;
                left: 0;
            }

            &::after {
                content: "";
                height: 60px;
                width: 1px;
                background-color: #ebebeb;
                position: absolute;
                top: -29px;
                left: 29px;
            }
        }

        .card-num {
            font-size: 140px;
            line-height: 140px;
            color: $color-primary;
            font-weight: 700;
            font-family: $oxygen;
            text-align: center;

            @media (max-width: 991px) {
                font-size: 80px;
                line-height: 80px;
            }
        }

        .card-heading {
            font-size: 30px;
            line-height: 39px;
            color: $color-secondary;
            font-weight: 500;
            font-family: "Roboto";
            text-align: center;
            margin-top: 10px;
            margin-bottom: 15px;

            @media (max-width: 767px) {
                font-size: 24px;
                line-height: 35px;
            }
        }
    }
}

.our-team-wrapper {
    overflow-x: hidden;
    background-color: $color-secondary;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background-image: url("../../images/illustration/line.svg");
        background-position: top center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
    }

    .section-head {
        .section-title {
            color: $color-white;
        }

        .heading-bg {
            bottom: -10px;
        }
    }

    .team-top-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }
}

.team-card-wrapper {
    margin-top: 50px;
    padding: 0px 20px;

    @media (max-width: 400px) {
        padding: 0px 10px;
    }

    .team-card {
        max-width: 100%;
        min-height: 560px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        z-index: 10;

        @media (max-width: 1400px) {
            min-height: 400px;
        }
    }

    .card-overlay {
        width: 100%;
        height: 100%;
        background-color: #001c4780;
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        z-index: -1;
    }

    .team-card:hover {
        .member-info {
            transform: translateY(0);
            opacity: 1;
            transition: transform 0.3s ease-in-out;
        }

        .card-overlay {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }
    }

    .member-info {
        transform: translateY(100%);
        opacity: 0;

        .name {
            color: $color-white;

            &:hover {
                color: $color-primary;
            }
        }

        .role {
            color: $color-gray-2;
            display: block;
        }
    }

    .card-left {
        padding: 10px;
    }

    .card-right {
        padding: 10px;

        &:hover {
            cursor: pointer;

            .social-items {
                text-align: center;
                width: max-content;
                transform: translateY(0);
                opacity: 1;
                transition: transform 0.3s ease-in;
            }

            .on-hover-icon i {
                transform: rotate(45deg);
                transition: transform 0.3s ease-in-out;
            }
        }

        .card-socials {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .social-items {
            opacity: 0;
            transform: translateY(50px);
        }

        .social-item {
            margin-bottom: 15px;

            .social-link {
                color: $color-white;

                &:hover {
                    color: $color-primary;
                }
            }
        }

        .on-hover-icon {
            width: 50px;
            height: 50px;
            background-color: $color-primary;
            color: $color-white;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}