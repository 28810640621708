.facts-wrapper {
    padding-bottom: 280px;
    .section-head {
        .heading-bg {
            top: -70px;
        }

        .section-title {
            color: $color-white;
        }
    }

    .body-text {
        margin-top: 20px;
        margin-bottom: 60px;
        color: $color-gray-2;
    }

    .facts-counter {
        .counter-item {
            &:not(:last-child) {
                margin-right: 40px;
            }
        }

        .counter-icon {
            margin-right: 20px;

            @media (max-width: 533px) {
                margin-bottom: 10px;
            }
        }

        .counter-num,
        .counter-plus {
            font-size: 38px;
            color: $color-primary;
            font-weight: 500;
        }

        .counter-text {
            font-size: 20px;
            line-height: 26px;
            color: $color-white;
            font-weight: 500;
        }
    }

    .right-content {
        @media (max-width: 991px) {
            margin-top: 100px;
        }
    }
}
