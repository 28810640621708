.header-wrapper {
    background-color: $color-white;

    .navbar {
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .navbar-content {
        width: 100%;
    }

    .logo-wrapper {
        width: 20%;
        background-color: $color-primary;
        margin-top: 0;
        margin-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 30px;
        display: flex;
        justify-content: center;

        @media (max-width: 767px) {
            width: 40%;
        }

        @media (max-width: 400px) {
            width: 50%;
        }
    }

    .menu-wrapper {
        width: 100%;

        @media (max-width: 1050px) {
            display: none !important;
        }
    }

    .top-menu {
        padding: 10px 50px;
        border-bottom: 1px solid #ededed;
    }

    .main-menu {
        padding: 23px 50px;
    }

    .contact-info {
        .contact-item {
            font-size: 14px;
            color: $color-gray;
            font-weight: 400;

            &:not(:last-child) {
                margin-right: 50px;

                @media (max-width: 1164px) {
                    margin-right: 20px;
                }
            }

            .item-icon {
                color: $color-secondary;
                margin-right: 5px;
            }
        }

        a {
            color: $color-gray;
        }
    }

    .social-icons {
        .social-link {
            font-size: 18px;
            color: $color-secondary;

            &:not(:last-child) {
                margin-right: 40px;

                @media (max-width: 1164px) {
                    margin-right: 20px;
                }
            }

            &:hover {
                color: $color-primary;
                transition: color 0.3s ease-in-out;
            }
        }
    }

    .main-menu-right {
        position: relative;

        &::before {
            content: "";
            width: 1px;
            height: 74px;
            background-color: #ededed;
            position: absolute;
            left: -110px;

            @media (max-width: 1164px) {
                left: -50px;
            }
        }

        .menu-right-search {
            font-size: 18px;
            color: $color-secondary;
            cursor: pointer;
            margin-right: 50px;

            @media (max-width: 1164px) {
                margin-right: 30px;
            }
        }

        .search-box {
            position: absolute;
            bottom: -85px;
            right: 0;
            z-index: 10;
            display: none;
            opacity: 0;

            &.show {
                display: block;
                opacity: 1;
                transition: opacity 0.3s ease-in;
            }

            input {
                min-width: 300px;
                border: 2px solid $color-primary;
                padding: 0px 10px;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;

                &::placeholder {
                    font-size: 14px;
                    color: $color-gray;
                    font-family: $oxygen;
                }
            }

            .search-button {
                width: 60px;
                height: 60px;
                background-color: $color-primary;
                color: $color-white;
                margin-left: -50px;
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }

    // MAIN MENU
    .main-menu {
        ul {
            line-height: 1;

            >li {
                display: inline-block;
                line-height: 1;

                &:not(:last-child) {
                    margin-right: 55px;
                }

                >a {
                    display: inline-block;
                    font-weight: 700;
                    font-size: 16px;
                    text-transform: capitalize;
                    color: $color-secondary;
                    line-height: 1;
                    overflow: hidden;

                    &.current {
                        color: $color-primary;
                    }
                }

                .icon {
                    font-size: 10px;
                    font-weight: 400;
                    margin-left: 3px;
                }

                &:hover {
                    >a {
                        color: $color-primary;
                        transition: color 0.3s ease-in-out;
                    }

                    >ul {
                        opacity: 1;
                        visibility: visible;
                        transform: translateY(0);
                    }
                }

                >ul {
                    position: absolute;
                    top: 100%;
                    min-width: max-content;
                    background-color: $color-primary;
                    left: 0;
                    z-index: 999;
                    box-shadow: 0 13px 35px -12px rgba(35, 35, 35, 0.15);
                    visibility: hidden;
                    opacity: 0;
                    transform: translateY(40px);
                    transition: all 0.5s ease-in-out;
                    padding: 10px 0px;
                    margin-top: 20px;

                    li {
                        display: block;

                        a {
                            display: block;
                            color: $color-white;
                            padding: 12px 25px;

                            &:hover {
                                color: #fff;
                            }
                        }
                    }

                    ul {
                        left: 100%;
                        top: 0;
                    }
                }
            }
        }
    }

    .mobile-menu-bottom {
        margin-top: 40px;

        .bottom-item {
            color: $color-white;
            text-align: center;
            font-size: 16px;

            .icon {
                margin-right: 10px;
            }
        }

        .button {
            margin-top: 30px;

            .icon {
                display: inline-block;
                margin-right: 10px;
            }
        }
    }

    .social-icons {
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: 30px;

        a {
            font-size: 18px;

            &:not(:last-child) {
                margin-right: 30px;
            }
        }
    }
}

// MOBILE MENU
.hamburger {
    color: $color-secondary;
    font-size: 30px;
    display: none;
    position: absolute;
    top: 23px;
    right: 50px;

    @media (max-width: 1050px) {
        display: block !important;
    }

    @media (max-width: 575px) {
        right: 20px;
    }

    i {
        cursor: pointer;
    }
}