.section-padding {
    padding: 160px 0px;

    @media (max-width: 991px) {
        padding: 100px 0px;
    }

    @media (max-width: 767px) {
        padding: 80px 0px;
    }
}

.body-text {
    font-size: 16px;
    line-height: 27px;
    color: $color-gray;
    font-weight: 400;

    &.light {
        color: $color-gray-2;
    }
}

/* ====================================================
    HEADINGS
 ====================================================== */

.section-head {
    position: relative;
    // margin-top: -5px;

    .heading-bg {
        position: absolute;
        font-weight: 700;
        font-size: 140px;
        letter-spacing: -3px;
        color: #94a6c41f;
        text-transform: uppercase;
        left: 0;
        z-index: -1;
        bottom: 23px;
        line-height: 1;

        @media (max-width: 991px) {
            font-size: 100px;
            left: 0;
            bottom: 0;
        }

        @media (max-width: 575px) {
            display: none;
        }
    }

    .section-title {
        font-size: 40px;
        line-height: 48px;
        color: #001c47;
        font-weight: 700;

        @media (max-width: 575px) {
            font-size: 32px;
            line-height: 40px;
        }
    }

    .heading-dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border: 3px solid $color-primary;
        margin-bottom: 0px;
        // margin-left: 10px;
        transform: translateX(10px);
    }
}

.heading-1 {
    font-size: 34px;
    line-height: 41px;
    color: $color-secondary;
    font-weight: 700;
    font-family: $roboto;

    @media (max-width: 767px) {
        font-size: 28px;
    }
}

.heading-2 {
    font-size: 30px;
    line-height: 39px;
    font-weight: 500;
    font-family: $roboto;

    @media (max-width: 767px) {
        font-size: 25px;
    }

    &.white {
        color: $color-white;
    }
}

/* ====================================================
    BUTTONS
 ====================================================== */

.button {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;

    &.button-primary {
        color: $color-white;
        background-color: $color-primary;
        padding: 15px 40px;
        position: relative;
        z-index: 2;

        @media (max-width: 575px) {
            font-size: 16px;
            padding: 15px 30px;
        }
    }

    &.button-primary:hover,
    &.button-primary:focus {
        transition: box-shadow 0.3s ease-in;
    }

    .icon {
        @media (max-width: 575px) {
            font-size: 14px;
        }
    }

    &.button-minimal {
        font-size: 16px;
        line-height: 27px;
        color: $color-secondary;
        font-weight: 700;

        .icon {
            font-size: 14px;
            margin-left: 5px;
        }
    }

    &.play-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        background-color: $color-primary;
        color: $color-white;
        border-radius: 50%;
        -webkit-animation: playVideo infinite 3.5s;
        animation: playVideo infinite 3s;

        @media (max-width: 575px) {
            height: 60px;
            width: 60px;
        }

        .play-icon {
            font-size: 25px;
            color: $color-primary;

            @media (max-width: 575px) {
                font-size: 16px;
            }
        }
    }
}

/* ====================================================
    SWIPER BUTTON
 ====================================================== */

.swiper-button-prev,
.swiper-button-next {
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 50px;
    background-color: $color-white;
    font-size: 20px;
    box-shadow: $box-shadow-1;
    position: absolute;
    cursor: pointer;

    @media (max-width: 767px) {
        width: 50px;
        height: 50px;
    }

    &:hover {
        background-color: $color-primary;
        color: $color-white;
        transition: background 0.3s ease-in;
    }
}

.swiper-button-prev::after,
.swiper-button-next::after {
    font-weight: bold;
    font-size: 16px;
    color: $color-gray;
}

/* ====================================================
    CIRCULAR PROGRESS
 ====================================================== */
.circular-progress {
    &.circle-1 {
        width: 280px;
        height: 280px;
    }

    &.circle-2 {
        width: 190px;
        height: 190px;
    }

    &.circle-3 {
        width: 230px;
        height: 230px;
    }
}

.CircularProgressbar {
    .CircularProgressbar-path {
        stroke: $color-primary;
    }

    .CircularProgressbar-text {
        fill: $color-primary;
    }

    .CircularProgressbar-trail {
        stroke: #efefef;
        stroke-width: 1;
        stroke-dasharray: 5;
    }
}