.contact-wrapper {
    .section-head {
        .heading-bg {
            left: -50px;
            bottom: 45px;
        }

        .body-text {
            padding-right: 130px;

            @media (max-width: 400px) {
                padding-right: 0;
            }
        }
    }

    .contact-profile {
        .profile-item {
            display: flex;
            align-items: center;
        }

        .profile-avatar {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            margin-right: 40px;
        }

        .name {
            font-size: 20px;
            line-height: 26px;
            color: $color-secondary;
            font-weight: 500;
        }

        .contact-medium {
            .medium-item {
                font-size: 14px;
                line-height: 18px;
                color: $color-secondary;
                font-weight: 400;
                font-family: $oxygen;
                position: relative;
                margin-left: 20px;

                &:not(:last-child) {
                    margin-bottom: 5px;
                }

                &::before {
                    content: "";
                    width: 5px;
                    height: 5px;
                    background-color: $color-primary;
                    position: absolute;
                    left: -20px;
                    top: 6px;
                }
            }
        }
    }

    .contact-form {
        background-color: $color-secondary;
        padding: 80px 60px;

        @media (max-width: 767px) {
            padding: 60px 30px;
        }

        .form-field,
        .form-text-field {
            width: 100%;
            padding: 10px 20px;
            background-color: transparent;
            border: 1px solid #626262;
            color: $color-gray-2;
        }

        .form-field {
            margin-bottom: 20px;
        }

        .form-text-field {
            min-height: 100px;
        }

        ::placeholder {
            font-size: 14px;
            line-height: 24px;
            color: $color-gray-2;
            font-weight: 400;
            font-family: $oxygen;
        }
    }
}

.contact-map-wrapper {
    position: relative;
    //min-height: 780px;
    z-index: -1;

    @media (max-width: 991px) {
        min-height: 500px;
    }

    @media (max-width: 767px) {
        min-height: 350px;
    }

    .map-marker {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .contact-map {
        width: 100%;
        min-height: 490px;

        @media (max-width: 575px) {
            min-height: 250px;
        }
    }
}