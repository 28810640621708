.footer-wrapper {
    background-color: $color-secondary;
    padding-top: 200px;

    .footer-logo {
        display: inline-block;
        margin-bottom: 20px;
    }

    .widget-about-social {
        margin-top: 30px;

        .social-link {
            font-size: 20px;
            color: $color-white;

            &:hover {
                color: $color-primary;
            }

            &:not(:last-child) {
                margin-right: 25px;
            }
        }
    }

    .widget-services {
        margin-left: 60px;

        @media (max-width: 991px) {
            margin-left: 0;
        }
    }

    .widget-subscribe {
        .form-input-wrapper {
            margin-top: 20px;
            position: relative;

            .form-input {
                width: 100%;
                padding: 10px 20px;
                background-color: transparent;
                border: 1px solid #143568;
                color: $color-white;

                &::placeholder {
                    font-size: 14px;
                    color: $color-gray-2;
                    font-weight: 400;
                    font-family: $oxygen;
                }
            }

            .subscribe-button {
                width: 42px;
                height: 42px;
                background-color: $color-primary;
                position: absolute;
                top: 4px;
                right: 3px;

                .button-icon {
                    color: $color-white;
                    font-size: 18px;
                }
            }
        }
    }

    .footer-widget {
        .widget-heading {
            font-size: 20px;
            color: $color-white;
            font-weight: 500;
            margin-bottom: 15px;
        }

        .widget-menu {
            .widget-item {
                margin-left: 15px;
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                &::before {
                    content: "";
                    width: 4px;
                    height: 4px;
                    background-color: $color-primary;
                    position: absolute;
                    left: -15px;
                    bottom: 12px;
                }
            }

            .widget-link {
                font-size: 16px;
                color: $color-gray-2;
                font-weight: 400;
                font-family: $oxygen;
            }
        }
    }

    .footer-contact-wrapper {
        margin-top: 50px;
        border: 1px solid #919191;


        @media (max-width: 991px) {
            border-bottom: 0;
        }
    }

    .footer-contact {
        height: 100%;
        padding: 50px 40px;
        border-right: 1px solid #919191;

        @media (max-width: 991px) {
            border-bottom: 1px solid #919191;
            border-right: 0;
            border-left: 0;
        }

        @media (max-width: 767px) {
            padding: 25px 20px;
        }

        .contact-icon {
            font-size: 40px;
            color: $color-gray;
            margin-right: 20px;
        }

        .contact-info {
            .top-text {
                font-size: 16px;
                color: $color-gray-2;
                font-weight: 400;
            }

            .main-text {
                font-size: 16px;
                color: $color-white;
                font-weight: 400;
                font-family: $roboto;
            }
        }
    }

    .footer-bottom-text {
        padding: 30px 0px;
    }

    .footer-cta {
        background-color: $color-primary;
        padding: 70px;
        margin-top: -310px;
        margin-bottom: 100px;
        position: relative;

        @media (max-width: 991px) {
            padding: 40px;
        }

        .body-text {
            color: $color-white;
            margin-top: 10px;
        }

        .button {
            background-color: $color-secondary;

            @media (max-width: 991px) {
                margin-top: 30px;
            }
        }
    }
}