.hero-wrapper {
    background-color: $color-secondary;
    position: relative;
    z-index: 9;

    .section-head {
        margin-bottom: 20px;

        .heading-bg {
            top: -50px;
            left: -100px;

            @media (max-width: 1813px) {
                left: -70px;
            }

            @media (max-width: 1400px) {
                font-size: 100px;
                left: 0px;
            }
        }

        // .heading-dot {
        //     margin-left: -10px;
        // }

        @media (max-width: 575px) {
            margin-bottom: 20px;
        }

        .section-title {
            font-size: 64px;
            line-height: 70px;
            color: $color-white;
            z-index: 4;
            font-family: "Oxygen", sans-serif;
            width: 90%;

            @media (max-width: 1937px) {
                width: 100%;
            }

            @media (max-width: 575px) {
                font-size: 40px;
                line-height: 50px;
            }
        }
    }

    .hero-content {
        display: flex;

        @media (max-width: 991px) {
            flex-direction: column;
        }
    }

    .left-content {
        width: 50%;
        padding-left: 200px;
        padding-right: 150px;
        padding-top: 200px;
        padding-bottom: 200px;
        background-repeat: no-repeat;
        background-position: bottom center;

        @media (max-width: 1813px) {
            padding-left: 200px;
            padding-right: 100px;
        }

        @media (max-width: 1600px) {
            padding-left: 100px;
            padding-right: 80px;
        }

        @media (max-width: 1400px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        @media (max-width: 991px) {
            width: 100%;
            padding-top: 100px;
            padding-bottom: 100px;
        }

        @media (max-width: 575px) {
            padding-top: 70px;
            padding-bottom: 70px;
            padding-left: 10px;
            background-position: center top;
        }

        .hero-header {
            margin-bottom: 10px;
            font-size: 20px;
            line-height: 30px;
            color: #ccddf6;
            font-weight: 700;
            width: 70%;

            @media (max-width: 575px) {
                font-size: 16px;
                width: 100%;
                margin-bottom: 5px;
            }
        }

        .hero-text {
            margin-bottom: 47px;
            font-size: 16px;
            line-height: 30px;
            color: #ccddf6;
            font-weight: 400;
            width: 70%;

            @media (max-width: 575px) {
                font-size: 14px;
                width: 100%;
                margin-bottom: 25px;
            }
        }
    }

    .right-content {
        width: 50%;

        @media (max-width: 991px) {
            width: 100%;
            height: 600px;
        }

        @media (max-width: 575px) {
            height: 250px;
        }
    }

    // HERO SLIDER CONTROLER
    .swiper-container {
        position: unset;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: absolute;
        top: 97.5%;
        left: auto;
        overflow: visible !important;

        @media (max-width: 767px) {
            top: 99.5%;
        }
    }

    .swiper-button-prev {
        right: 109px;

        @media (max-width: 767px) {
            right: 60px;
        }
    }

    .swiper-button-next {
        right: 10px !important;
    }
}

// SECTION BANNER
.banner-wrapper {
    position: relative;
    min-height: 500px;

    @media (max-width: 767px) {
        min-height: 300px;
    }

    .section-head {
        .heading-bg {
            bottom: -25px;
            left: -50px;
            color: $color-secondary;
        }

        .section-title {
            color: $color-white;
        }
    }

    .banner-overlay {
        width: 100%;
        background-color: #0a0a0a81;
        // background-image: url("../img/illustration/line.svg");
        background-size: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .banner-content {
        padding-top: 230px;

        @media (max-width: 767px) {
            padding-top: 115px;
        }
    }

    .breadcrumb-wrap {
        margin-top: 20px;

        @media (max-width: 575px) {
            margin-top: 10px;
        }
    }

    .breadcrumb {
        background: transparent;
        padding: 0;
    }

    .breadcrumb-item {
        font-size: 16px;
        color: $color-gray-2;
        font-weight: 400;
        text-transform: capitalize;
    }

    .breadcrumb-item.active {
        color: $color-primary  !important;
    }

    .breadcrumb-item+.breadcrumb-item::before {
        color: $color-gray-2;
    }
}