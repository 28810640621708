.query-wrapper {
    margin-bottom: 110px;
    position: relative;

    .section-head {
        .heading-bg {
            top: -60px;
        }
    }

    .left-content {
        position: relative;

        &::before {
            content: "";
            width: 100%;
            height: 100%;
            background-image: url("../../images/illustration/faq-illustration.png");
            position: absolute;
            bottom: 313px;
            left: -323px;
            background-repeat: no-repeat;
            background-size: cover;
            z-index: -1;
        }
    }

    .right-content {
        .section-head {
            margin-top: 20px;
            .heading-bg {
                @media (max-width: 991px) {
                    top: -40px;
                }
            }
        }
    }
}

.accordion {
    margin-top: 40px;

    .accordion-item {
        margin-bottom: 15px;
        border: 1px solid rgba(0, 0, 0, 0.125) !important;
    }

    .accordion-body {
        font-size: 16px;
        line-height: 27px;
        color: $color-gray;
        font-weight: 400;
        font-family: $oxygen;
    }

    .accordion-button {
        font-size: 20px;
        line-height: 26px;
        padding: 20px 30px;
        color: $color-secondary;
        font-weight: 500;
        font-family: $roboto;
        border-radius: 0;
    }

    .accordion-button:not(.collapsed) {
        background-color: $color-primary;
        color: $color-white;
    }

    // .accordion-button::after {
    //     content: "\f067";
    //     font-family: "Font Awesome 5 Pro";
    //     position: absolute;
    //     right: 0;
    //     top: 50%;
    //     font-weight: 400;
    //     transform: translateY(-50%);
    //     color: $color-white;
    //     background-image: none;
    // }

    .accordion-item:first-of-type {
        border-radius: 0;
    }

    .accordion-item:last-of-type {
        border-radius: 0;
    }
}
