/* ------------------------------------------------------------------
    Template All Variables Defined - Value Change with Your's Need
 -------------------------------------------------------------------- */

// COLORS
$color-primary: #FF8500;
$color-secondary: #363636;
$color-gray: #707070;
$color-gray-2: #aeaeae;
$color-white: #ffffff;

// BOX SHADOWS
$box-shadow-1: rgba(149, 157, 165, 0.2) 0px 8px 24px;
$box-shadow-2: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

$sticky-bg-1: #ff8f9f;
$sticky-bg-2: #ea5c50;

@mixin border-radius($r) {
    -webkit-border-radius: $r;
    -moz-border-radius: $r;
    -ms-border-radius: $r;
    border-radius: $r;
}

@mixin transition($transition) {
    -webkit-transition: all $transition ease-in-out;
    -moz-transition: all $transition ease-in-out;
    -ms-transition: all $transition ease-in-out;
    -o-transition: all $transition ease-in-out;
    transition: all $transition ease-in-out;
}

@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}

@mixin coverbg {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
}

@mixin appearance {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        cursor: pointer;
        display: block;
        width: 8px;
        color: #333;
        text-align: center;
        position: relative;
    }
}

@mixin overlay($color, $opacity) {
    z-index: 1;
    position: relative;

    &:before {
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        content: "";
        position: absolute;
        background: rgba($color, $opacity);
        @include transition(all 0.3s ease);
    }
}

@mixin absolute($z-index) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: $z-index;
}

@mixin before($zindex) {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: $zindex;
}

@function calculateRem($size) {
    $rem-size: $size / 16px;
    @return $rem-size * 1rem;
}

@mixin font-size($size: 16px) {
    font-size: $size;
    font-size: calculateRem($size);
}

@mixin letter-spacing($letter-track, $font-size) {
    letter-spacing: $letter-track * $font-size / 1000 + px;
}

@mixin align-center($type: "all") {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    // all
    @if $type=="all" {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        // horizontal
    }

    @else if $type=="horizontal" {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        // vertical
    }

    @else if $type=="vertical" {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        // vertical
    }

    @else if $type=="bottom" {
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }
}

@mixin placeholder($color, $font_size, $font_weight) {
    &::-webkit-input-placeholder {
        color: $color;
        font-size: $font_size;
        font-weight: $font_weight;
    }

    &::-moz-placeholder {
        color: $color;
        font-size: $font_size;
        font-weight: $font_weight;
    }

    &:-ms-input-placeholder {
        color: $color;
        font-size: $font_size;
        font-weight: $font_weight;
    }

    &:-moz-placeholder {
        color: $color;
        font-size: $font_size;
        font-weight: $font_weight;
    }
}